<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <div class="title">支付成功</div>
    <img src="./img/success.png" />
    <div class="content"><span>订单号：</span>{{ code }}</div>
    <div class="btn" @click="goback">继续点单</div>
  </div>
</template>

<script>
export default {
  name: "deliverySuccess",
  data() {
    return { code: null, isOld: localStorage.getItem("isOld") };
  },
  created() {
    this.code = this.$route.query.code;
  },
  methods: {
    goback() {
      const backLength = window.history.length - 1;
      this.$router.go(-backLength);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-top: 42px;
    padding-bottom: 92px;
  }
  .content {
    font-size: 56px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #e02020;
    text-align: center;
    span {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  img {
    display: block;
    width: 444px;
    margin: 0 auto 112px;
  }
}
.isOld {
  .btn {
    width: 550px;
    height: 86px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 86px;
    text-align: center;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-top: 42px;
    padding-bottom: 92px;
  }
  .content {
    font-size: 66px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #e02020;
    text-align: center;
    span {
      font-size: 42px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  img {
    display: block;
    width: 464px;
    margin: 0 auto 112px;
  }
}
</style>
